import React from 'react'
import About from '../components/About'
import '../css/AboutMe.css'

const AboutMe = () => {
  return (
    <About />
  )
}

export default AboutMe