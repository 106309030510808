import React from 'react'
import Home from '../components/Home'
import "../css/App.css";
import "../css/Homepage.css";

const HomePage = () => {
  return (
    <Home />
  )
}

export default HomePage